<template>
  <div class="no-pad-wrap">
    <tabs>
      <template v-slot:instruments>
        <InstrumentDropdown class="normal" />
      </template>
      <template v-slot:before>
        <input
          v-model="gridFilter"
          placeholder="Filter grid"
          class="grid-filter"
          type="text"
        >
      </template>
      <tab :index="0">
        <GridSetup
          :processed-data="tradeMetrics"
          :search-filter="gridFilter"
          :pagination="true"
        />
      </tab>
      <tab :index="1">
        <EChartHeatmap />
        <EChartSingleLineScatter />
      </tab>
      <!-- <template v-slot:after>
        <p>otpd: {{ tradeMetricsTotals.one_trade_per_day.toFixed(2) }}%</p>
        <p>ROI: ${{ tradeMetricsTotals.return_on_investment.toFixed(2) }}</p>
        <p>RET: {{ tradeMetricsTotals.return.toFixed(2) }}%</p>
      </template> -->
    </tabs>
  </div>
</template>

<script>

import { ref } from '@vue/composition-api'
import InstrumentDropdown from '@/views/components/InstrumentDropdown.vue'
import useTrades from '@/composables/trades/useTrades'
import useInstruments from '@/composables/useInstruments'
import useStrategies from '@/composables/strategies/useStrategies'
import tab from '../components/appTab.vue'
import tabs from '../components/appTabs.vue'
import EChartHeatmap from '../components/echart/EChartHeatmap.vue'
import GridSetup from '../components/agGrid/GridSetup.vue'
// import MetricsGrid from '../components/metrics/MetricsGrid.vue'
// import MetricsGridGroup from '../components/MetricsGridGrouped.vue'
import EChartSingleLineScatter from '../components/echart/EChartSingleLineScatter.vue'

export default {
  components: {
    EChartHeatmap,
    // MetricsGrid,
    GridSetup,
    EChartSingleLineScatter,
    InstrumentDropdown,
    tabs,
    tab,
  },
  setup() {
    const { instrumentActive } = useInstruments()
    const { strategyActive } = useStrategies({ instrumentActive })
    const { calculateTradeMetrics, tradeMetrics, tradeMetricsTotals } = useTrades({ instrumentActive, strategyActive })

    calculateTradeMetrics()

    const gridFilter = ref()

    return {
      tradeMetrics,
      tradeMetricsTotals,
      gridFilter,
    }
  },
}
</script>

<style lang="scss">
</style>
