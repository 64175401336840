<template>
  <div class="d-flex flex-column">
    <h5>Total Return Per Hour</h5>
    <div id="singleScatter" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import darkTheme from './darkTheme.json'
import lightTheme from './lightTheme.json'

export default {
  data() {
    return {
      hours: [
        '12am',
        '1am',
        '2am',
        '3am',
        '4am',
        '5am',
        '6am',
        '7am',
        '8am',
        '9am',
        '10am',
        '11am',
        '12pm',
        '1pm',
        '2pm',
        '3pm',
        '4pm',
        '5pm',
        '6pm',
        '7pm',
        '8pm',
        '9pm',
        '10pm',
        '11pm',
      ],
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      singleScatterChart: null,
      chartDom: null,
      chartOptions: {
        tooltip: {
          position(pos, params, dom, rect, size) {
            let obj = {}
            if (pos[0] < size.viewSize[0] / 2) {
              obj = {
                top: rect.y - rect.height * 1.5,
                left: rect.x + rect.width,
              }
            } else {
              obj = {
                top: rect.y - rect.height * 1.5,
                right: size.viewSize[0] - rect.x,
              }
            }
            return obj
          },
          formatter: params => `
          Day: ${params.data[2]} <br>
          Time: ${params.data[0]} <br>
          Trades: ${params.data[3]} <br>
          Total Ret: ${params.data[1].toFixed(1)}%
        `,
        },
        title: [],
        singleAxis: [],
        series: [],
      },
      // chart data
      rawAvg: [],
      seriesData: [],
      processedData: [],
      chartData: [],
      noResult: [],
      allTotalReturns: [],
    }
  },
  computed: {
    rawChartData() {
      return this.$store.state.trades[this.strategy.toLowerCase()][this.pair]
    },
    pair() {
      return this.$store.state.activePair
    },
    strategy() {
      return this.$store.state.activeStrategyType
    },
  },
  watch: {
    rawChartData() {
      this.setArrayHours()
    },
  },
  mounted() {
    document.addEventListener('darkMode', () => {
      this.themeChange()
    })
    window.addEventListener('resize', () => {
      this.singleScatterChart.resize()
    })
    this.chartSetup()
    this.setArrayHours()
  },
  methods: {
    themeChange() {
      this.chartDom = document.getElementById('singleScatter')
      // register themes
      echarts.registerTheme('darkTheme', darkTheme)
      echarts.registerTheme('lightTheme', lightTheme)
      if (this.singleScatterChart != null) {
        this.singleScatterChart.dispose()
      }
      if (this.$isDark.value) {
        this.singleScatterChart = echarts.init(this.chartDom, 'darkTheme')
      } else {
        this.singleScatterChart = echarts.init(this.chartDom, 'lightTheme')
      }
      this.singleScatterChart.setOption(this.chartOptions)
    },
    chartSetup() {
      this.days.forEach((day, idx) => {
        this.chartOptions.title.push({
          textBaseline: 'middle',
          top: `${((idx + 0.5) * 100) / 7}%`,
          left: 0,
          text: day,
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
          },
        })
        this.chartOptions.singleAxis.push({
          type: 'category',
          boundaryGap: true,
          data: this.hours,
          top: `${(idx * 100) / 7 + 4}%`,
          height: `${100 / 7 - 10}%`,
          width: '',
          left: '40px',
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            show: true,
            interval: 0,
            fontSize: '11px',
            align: 'center',
          },
        })
        this.chartOptions.series.push({
          singleAxisIndex: idx,
          coordinateSystem: 'singleAxis',
          type: 'scatter',
          data: [],
          itemStyle: {
            color: params => {
              if (params.data[1] < 0) {
                return '#F57077'
              }
              return '#55C376'
            },
          },
          symbolSize: dataItem => {
            let size = dataItem[1] * 3.4
            if (Math.abs(size) > 80) {
              size = 80
            }
            return size
          },
        })
      })
    },
    setArrayHours() {
      const data = this.rawChartData
      this.rawAvg = []
      for (let i = 0; i < data.length; i += 1) {
        const tradeEntryTime = new Date(0) // Epoch
        tradeEntryTime.setUTCMilliseconds(data[i].entryTime)
        const entryHour = tradeEntryTime.getHours()
        const entryDay = tradeEntryTime.getDay()
        let numberResult = 0
        if (Number.isNaN(data[i].result) || data[i].result === 'null') {
          this.noResult.push('no result for trade: ', i)
        } else if (data[i].result > 0) {
          const result = Math.abs(data[i].pipsTP / data[i].pipsSL)
          numberResult = parseFloat(result.toFixed(2))
        } else {
          numberResult = -1
        }
        if (numberResult !== 0) {
          this.rawAvg.push({ entryDay, entryHour, avgReturn: numberResult }) // push each trade
        }
      }
      this.mergeArray()
    },
    mergeArray() {
      this.seriesData = []
      const tempData = this.$lod(this.rawAvg) // input array
        .groupBy('entryDay')
        .map((data, entryDay) => ({
          entryDay,
          trades: data.map(trades => ({
            entryDay,
            return: trades.avgReturn,
            entryHour: trades.entryHour,
          })),
        }))
        .value()
      for (let i = 0; i < tempData.length; i += 1) {
        this.seriesData[i] = this.$lod(tempData[i].trades)
          .groupBy('entryHour')
          .value()
      }
      this.sumData()
    },
    sumData() {
      this.allTotalReturns = []
      this.processedData = []
      this.seriesData.forEach(entryDay => {
        const value = Object.values(entryDay)
        let stringHour = ''
        let rawDay = 0
        let stringDay = ''
        let sum = 0
        let n = 0
        value.forEach(entryHour => {
          const trades = Object.values(entryHour)
          const times = Object.keys(entryHour)
          // eslint-disable-next-line no-restricted-syntax
          for (const time of times) {
            sum += trades[time].return
            n += 1
            stringHour = this.hourToString(trades[time].entryHour)
            stringDay = this.dayToString(trades[time].entryDay)
            rawDay = trades[time].entryDay
          }
          const avgRet = sum / n
          sum = 0
          const fixedRet = avgRet.toFixed(2)
          this.allTotalReturns.push({ totalRetun: fixedRet * n })
          this.processedData.push({
            day: Number(rawDay),
            stringDay,
            hour: stringHour,
            totalReturn: fixedRet * n,
            allTrades: n,
          })
          n = 0
        })
      })
      this.setData()
    },
    setData() {
      for (let i = 0; i < this.chartOptions.series.length; i += 1) {
        this.chartOptions.series[i].data = []
      }
      this.processedData.forEach(trade => {
        this.chartOptions.series[trade.day].data.push([
          trade.hour,
          trade.totalReturn || '0',
          trade.stringDay,
          trade.allTrades,
        ])
      })
      this.themeChange()
    },
    hourToString(hour) {
      let timeFormat = ''
      if (hour === 0) {
        timeFormat = '12am'
      } else if (hour < 12) {
        timeFormat = `${hour}am`
      } else if (hour === 12) {
        timeFormat = `${hour}pm`
      } else {
        const pmH = hour - 12
        timeFormat = `${pmH}pm`
      }
      return timeFormat
    },
    dayToString(day) {
      let stringDay = ''
      if (day === '0') {
        stringDay = 'Sun'
      } else if (day === '1') {
        stringDay = 'Mon'
      } else if (day === '2') {
        stringDay = 'Tue'
      } else if (day === '3') {
        stringDay = 'Wed'
      } else if (day === '4') {
        stringDay = 'Thu'
      } else if (day === '5') {
        stringDay = 'Fri'
      } else stringDay = 'Sat'
      return stringDay
    },
  },
}
</script>

<style>
#singleScatter {
  width: 100%;
  min-width: 1080px;
  min-height: 500px;
}
</style>
