<template>
  <div class="d-flex flex-column mt-2">
    <h5 class="m-0">
      Avg. Return Per Hour
    </h5>
    <div id="heatmapChart" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import darkTheme from './darkTheme.json'
import lightTheme from './lightTheme.json'

export default {
  data() {
    return {
      hours: [
        '12am',
        '1am',
        '2am',
        '3am',
        '4am',
        '5am',
        '6am',
        '7am',
        '8am',
        '9am',
        '10am',
        '11am',
        '12pm',
        '1pm',
        '2pm',
        '3pm',
        '4pm',
        '5pm',
        '6pm',
        '7pm',
        '8pm',
        '9pm',
        '10pm',
        '11pm',
      ],
      days: ['Sat', 'Fri', 'Thu', 'Wed', 'Tue', 'Mon', 'Sun'], // reversed so Mon shows at top
      seriesData: [],
      pairing: this.$store.state.activePair,
      rawAvg: [],
      processedData: [],
      allReturns: [],
      noResult: [],
      chartDom: null,
      heatmapChart: null,
      chartOptions: null,
    }
  },
  computed: {
    rawChartData() {
      return this.$store.state.trades[this.strategy.toLowerCase()][this.pair]
    },
    pair() {
      return this.$store.state.activePair
    },
    strategy() {
      return this.$store.state.activeStrategyType
    },
    chartOption() {
      return {
        type: 'heatmap',
        tooltip: {
          trigger: 'item',
          position: (pos, params, dom, rect, size) => {
            let obj = {}
            if (pos[0] < size.viewSize[0] / 2) {
              obj = {
                top: rect.y - rect.height * 1.5,
                left: rect.x + rect.width,
              }
            } else {
              obj = {
                top: rect.y - rect.height * 1.5,
                right: size.viewSize[0] - rect.x,
              }
            }
            return obj
          },
          formatter: params => `
          Day: ${params.data[1]} <br>
          Time: ${params.data[0]} <br>
          Trades: ${params.data[3]} <br>
          Total Ret: ${(params.data[2] * params.data[3]).toFixed(1)}%
        `,
        },
        grid: {
          backgroundColor: '#transparent',
          width: '',
          top: '46px',
          left: '36px',
        },
        visualMap: {
          type: 'piecewise',
          dimension: 2,
          splitNumber: 5,
          pieces: [
            { min: -1, max: -0.15 },
            { min: -0.14, max: -0.01 },
            { min: 0, max: 0.1 },
            { min: 0.11, max: 0.2 },
            { min: 0.21, max: Math.max(...this.allReturns) },
          ],
          calculable: true,
          text: ['max', 'min'],
          formatter: value => `${value.toFixed(1)}%`,
          orient: 'horizontal',
          top: '0px',
          right: '0px',
        },
        xAxis: {
          type: 'category',
          data: this.hours,
          splitArea: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            inside: false,
            fontSize: '11px',
            borderRadius: 100,
            lineHeight: 0,
            padding: [10, 8, 10, 8],
            margin: 4,
          },
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          type: 'category',
          data: this.days,
          show: true,
        },
        series: {
          name: 'Avg. Return',
          type: 'heatmap',
          coordinateSystem: 'cartesian2d',
          data: this.chartData,
          label: {
            show: true,
            formatter: value => `${value.value[2]}%`,
          },
          emphasis: {
            itemStyle: {
              color: '#fff',
              borderColor: '#000',
            },
          },
        },
      }
    },
    chartData() {
      const chartData = []
      this.processedData.forEach(trade => {
        chartData.push([
          trade.hour,
          trade.day,
          trade.avgRet || '0',
          trade.allTrades,
        ])
      })
      return chartData
    },
  },
  watch: {
    rawChartData() {
      this.themeChange()
    },
  },
  mounted() {
    document.addEventListener('darkMode', () => {
      this.themeChange()
    })
    window.addEventListener('resize', () => {
      this.heatmapChart.resize({
        width: null,
      })
    })
    this.themeChange()
  },
  methods: {
    themeChange() {
      this.setArrayHours()
      this.mergeArray()
      this.sumData()
      this.chartDom = document.getElementById('heatmapChart')
      echarts.registerTheme('darkTheme', darkTheme)
      echarts.registerTheme('lightTheme', lightTheme)
      if (this.heatmapChart != null) {
        this.heatmapChart.dispose()
      }
      if (this.$isDark.value) {
        this.heatmapChart = echarts.init(this.chartDom, 'darkTheme')
      } else {
        this.heatmapChart = echarts.init(this.chartDom, 'lightTheme')
      }
      this.heatmapChart.setOption(this.chartOption, {
        replaceMerge: ['xAxis', 'yAxis', 'series'],
        lazyUpdate: true,
      })
    },
    setArrayHours() {
      const data = this.rawChartData
      this.rawAvg = []
      for (let i = 0; i < data.length; i += 1) {
        const entryDate = new Date(0) // Epoch
        entryDate.setUTCMilliseconds(data[i].entryTime)
        const entryHour = entryDate.getHours()
        const day = entryDate.getDay()
        let numberResult = 0
        if (Number.isNaN(data[i].result) || data[i].result === 'null') {
          this.noResult.push('no trade')
        } else if (data[i].result > 0) {
          const result = Math.abs(data[i].pipsTP / data[i].pipsSL)
          numberResult = parseFloat(result.toFixed(2))
        } else {
          numberResult = -1
        }
        if (numberResult !== 0) {
          this.rawAvg.push({ day, entryHour, avgRet: numberResult })
        }
      }
      //
    },
    mergeArray() {
      this.seriesData = []
      const tempData = this.$lod(this.rawAvg) // input array
        .groupBy('day')
        .map((data, day) => ({
          day,
          trades: data.map(trades => ({
            day,
            avgRet: trades.avgRet,
            entryHour: trades.entryHour,
          })),
        }))
        .value()
      //
      for (let i = 0; i < tempData.length; i += 1) {
        this.seriesData[i] = this.$lod(tempData[i].trades)
          .groupBy('entryHour')
          .value()
      }
    },
    sumData() {
      this.allReturns = []
      this.processedData = []
      this.seriesData.forEach(day => {
        const value = Object.values(day)
        let entryHour = null
        let stringDay = ''
        let sum = 0
        let n = 0
        value.forEach(hour => {
          const trades = Object.values(hour)
          const times = Object.keys(hour)
          // eslint-disable-next-line no-restricted-syntax
          for (const time of times) {
            sum += trades[time].avgRet
            n += 1
            entryHour = this.hourToString(trades[time].entryHour)
            stringDay = this.dayToString(trades[time].day)
          }
          const avgRet = sum / n
          sum = 0
          const fixedRet = avgRet.toFixed(2)
          this.allReturns.push(fixedRet)
          this.processedData.push({
            day: stringDay,
            hour: entryHour,
            avgRet: fixedRet,
            allTrades: n,
          })
          n = 0
        })
        //
      })
    },
    hourToString(hour) {
      let timeFormat = ''
      if (hour === 0) {
        timeFormat = '12am'
      } else if (hour < 12) {
        timeFormat = `${hour}am`
      } else if (hour === 12) {
        timeFormat = `${hour}pm`
      } else {
        const pmH = hour - 12
        timeFormat = `${pmH}pm`
      }
      return timeFormat
    },
    dayToString(day) {
      let stringDay = ''
      if (day === '0') {
        stringDay = 'Sun'
      } else if (day === '1') {
        stringDay = 'Mon'
      } else if (day === '2') {
        stringDay = 'Tue'
      } else if (day === '3') {
        stringDay = 'Wed'
      } else if (day === '4') {
        stringDay = 'Thu'
      } else if (day === '5') {
        stringDay = 'Fri'
      } else stringDay = 'Sat'
      return stringDay
    },
  },
}
</script>

<style lang="scss" scoped>
#heatmapChart {
  margin-top: -20px;
  width: 100%;
  min-width: 1080px;
  min-height: 480px;
}
</style>
